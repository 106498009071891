body {
  font-family: 'Chela One', system-ui !important;
  margin-bottom: 150px !important;
  height:100%;
}
#jokeometer {
  height: 100%;
  display: flex;
  flex-direction: column;
}
/* for bigger screens */
@media (min-width: 768px) {
  #jokeometer {
    height: 100vh;
  }
}
footer {
  width: 100%;
  bottom: 0;
  border-top: 2px solid #ddd;
  box-shadow: 0 0 10px 0 #ddd;
  background-color: #FFF;
}

/* for bigger screens */
@media (min-width: 768px) {
  footer .footer-notice {
    font-size: 1.3em;
  }
  footer svg {
    width: 20px !important;
    height: 20px !important;
  }
}