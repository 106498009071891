.jokometian-view-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2vh;
  }

/* Container for the flipping card */
.flip-card {
    perspective: 1000px;
    width: 23rem;
}

/* This container is needed to position the front and back side */
.flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    transition: transform 0.6s;
    transform-style: preserve-3d;
}

/* Flip the inner container when the state is flipped */
.flip-card.flipped .flip-card-inner {
    transform: rotateY(180deg);
}

/* Position the front and back side */
.flip-card-front,
.flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    /* Hide the back side when facing away */
}

/* Style the back side */
.flip-card-back {
    transform: rotateY(180deg);
}

.jokes-arsenal {
    width: 23rem;
    display: block;
    overflow: auto;
}
/* Min height for firefox */
@-moz-document url-prefix() {
    .jokes-arsenal {
        min-height: 700px;
    }
}
.jokes-arsenal .card-body {
    font-family: Fredoka, system-ui !important;
}

.copy-toast-container {
  font-family: 'Fredoka', system-ui !important;
  position: absolute;
  z-index: 1000;
}
.copy-toast-container > :not(:last-child) {
  margin-bottom: 0.5rem !important;
} 

.share-url-button {
  user-select: none !important;
}
.copy-toast-container .btn-close {
  float: right !important;
}


