@keyframes swipeRightLeft {

  0%,
  100% {
    transform: translateX(0);
  }

  25% {
    transform: translateX(20px);
  }

  75% {
    transform: translateX(-40px);
  }
}

.swipe-animation {
  animation: swipeRightLeft 2s ease-in-out infinite;
}

.jokeometer-instuctions {
  font-family: 'Fredoka', system-ui !important;
}
.jokeometer-instuctions h1, h2 {
  font-family: 'Chela One', system-ui !important;
}
